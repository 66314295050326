import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { BgColor, TextColor } from '../../components/Color/Color';
import { getmember, updatemembers } from '../../ReduxToolkit/Slice/Member/Member';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import MemberNotification from './MemberNotification';
import { Link } from 'react-router-dom';
import Model from '../../components/Model/Model';
import AddMember from './AddMember';
import Input from '../../components/Input/Input';
import Button from '../../components/Button/Button';
import PageTitle from '../../components/Title/PageTitle';
import { dummyImage } from '../../components/Img/Img';

function Member() {
    const dispatch = useDispatch();
    const closeButtonRef = useRef(null);
    const { status, members, error,loadingStatus } = useSelector((state) => state.member);
    
    console.log(members)
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [gender, setgender] = useState("");
    const [timeSlot, setTimeSlot] = useState("");
    const [address, setAddress] = useState("");
    const [city, setCity] = useState("");
    const [state, setState] = useState("");

    // single data
    const [selectedId, setSelectedId] = useState(null);
    const handlid = (id) => {
        const selectedmembers = members.find(plan => plan._id === id);
        setName(selectedmembers.name);
        setEmail(selectedmembers.email);
        setPhone(selectedmembers.phone);
        setgender(selectedmembers.gender);
        setTimeSlot(selectedmembers.timeSlot);
        setAddress(selectedmembers.address);
        setCity(selectedmembers.city);
        setState(selectedmembers.address);
        setSelectedId(id);
    };
    // update 
    const updateMember = (e) => {
        e.preventDefault()
        const data = {
            name,
            email,
            phone,
            gender,
            timeSlot,
            address,
            city,
            state
        }
        dispatch(updatemembers({ selectedId, data }))

    }
    useEffect(() => {
        if (loadingStatus === "succeeded") {
        
          closeButtonRef.current.click();
        }
      }, [loadingStatus]);
    // filter
    const [limit] = useState(20)
    const [pagination, setpagination] = useState(1)
    const [search, setsearch] = useState("")
    const [Gender, setGender] = useState("")

    // get 
    useEffect(() => {
        if (status === "idle") {
            dispatch(getmember({ limit, pagination, search, Gender }));
        }
    }, [status, limit, pagination, search, Gender, dispatch]);

    //  fillter 
    useEffect(() => {
        dispatch(getmember({ limit, pagination, search, Gender }));
    }, [limit, pagination, search, Gender]);
    
    //pagination 
    const handlePaginationChange = (newPage) => {
        setpagination(newPage);
        dispatch(getmember({ limit, pagination: newPage, search, Gender }));
    };

    return (
        <>
            <div>
                <div className='row d-flex justify-content-between'>
                    <div className="col-lg-12">
                        <PageTitle children={`Gym Members (${members.length ? members.length : ""})`} />
                    </div>
                    <div className="col-lg-2 col-md-2 col-6 my-2">
                        <select name="gender" className='form-control' id="genderInput"
                            value={Gender}
                            onChange={(e) => setGender(e.target.value)}>
                            <option value="">Select Gender</option>
                            <option value="Male">Male</option>
                            <option value="Female">Female</option>
                        </select>
                    </div>
                    <div className="col-lg-6 col-md-6 col-12">
                        <div className="row justify-content-end text-end">
                            <div className="col-lg-6 col-md-8 col-7">
                                <Input type="text" placeholder='Search here' onChange={(e) => setsearch(e.target.value)} />
                            </div>
                            <div className="col-lg-3 col-md-4 col-5">
                                <AddMember />
                            </div>
                        </div>
                    </div>

                </div>
                <div className='table-responsive'>
                    <table className="table table-hover">
                        <thead className={`table-${BgColor}`}>
                            <tr>
                                <th scope="col">S.no</th>
                                <th scope="col">Name</th>
                                <th scope="col">Phone</th>
                                <th scope="col">Plan</th>
                                <th scope="col">Time</th>
                                <th scope="col">Attence</th>
                                <th scope="col">Action (<MemberNotification />)</th>
                            </tr>
                        </thead>
                        <tbody>
                            {status === "loading" && (
                                <tr className='my-2'>
                                    <td colSpan={8}>
                                        <Skeleton count={10} height={60} />
                                    </td>
                                </tr>
                            )}
                            {status === "failed" && (
                                <tr>
                                    <td colSpan={8}>
                                        <p className='text-center fw-bold'>{error}</p>
                                    </td>
                                </tr>
                            )}
                            {status === "succeeded" && members && members.map((member, index) => (
                                <tr key={member._id}>
                                    <th scope="row">
                                        <div className='position-relative'>
                                            <span className={`position-absolute bottom-0 start-0  rounded-circle text-${TextColor} bg-${BgColor} text-center`} style={{ fontSize: "10px", height: "17px", width: "17px" }}>{index + 1}</span>
                                            <Link to={`/memberProfile/${member._id}`}>   {
                                                member.image ?
                                                    <img
                                                        src={member.image}
                                                        height={"50px"}
                                                        width={"50px"}
                                                        className="rounded-circle img-fit"
                                                        alt="img"
                                                    /> : <img
                                                        src={dummyImage}
                                                        height={"50px"}
                                                        width={"50px"}
                                                        className="rounded-circle img-fit"
                                                        alt="img"
                                                    />}
                                            </Link>
                                        </div>
                                    </th>
                                    <td>{member.name.charAt(0).toUpperCase() + member.name.slice(1)}</td>
                                    <td className='' >{member.phone}</td>
                                    <td>{member.plan ? <span>{member.plan.name}<span>/₹</span>{member.plan.price}</span> : <i className='px-2 rounded-pill bg-danger text-white'>Expired</i>}</td>
                                    <td className='' >{member.timeSlot}</td>
                                    <td className='' ><Link to={`/attendance/${member._id}`}><i className={`bg-${BgColor} text-${TextColor} px-2 rounded-pill`}>Attendance</i></Link></td>
                                    <td className='cursor'>
                                        <span onClick={() => handlid(member._id)}><i className={`bi bi-pencil-square text-${BgColor} fs-5 mx-1`} data-bs-toggle="modal" data-bs-target="#exampleModal"></i></span>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
                <div className="row justify-content-between">
                    <div className="col-lg-4">
                        <p>{`Showing ${pagination} to ${members.length} of ${members.length} Projects`}</p>
                    </div>
                    <div className="col-lg-4 d-flex justify-content-end">
                        <div className="pagination">
                            {/* Previous button */}
                            <button
                                className={`mx-1 btn bg-${BgColor} text-${TextColor}`}
                                onClick={() => handlePaginationChange(Math.max(1, pagination - 1))}
                                disabled={pagination === 1}
                            >
                                Previous
                            </button>
                            {/* Next button */}
                            <button
                                className={`mx-1 btn bg-${BgColor} text-${TextColor}`}
                                onClick={() => handlePaginationChange(pagination + 1)}
                                disabled={members.length < limit}
                            >
                                Next
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            {/* Modal  */}
            <Model id='exampleModal' title='Update Member'>
                <form onSubmit={updateMember}>
                    <div className="row text-start">
                        <Input label="Name" type="text" bodyclass="col-lg-6" placeholder="Enter Your Name" required value={name} onChange={(e) => setName(e.target.value)} />
                        <Input label="Email" type="email" bodyclass="col-lg-6" placeholder="Enter your email" required value={email} onChange={(e) => setEmail(e.target.value)} />
                        <Input
                            label="Phone"
                            type="tel"
                            bodyclass="col-lg-6"
                            placeholder="Enter your phone number"
                            required
                            maxLength={10}
                            minLength={10}
                            value={phone}
                            onChange={(e) => setPhone(e.target.value)}
                        />
                        <div className="mb-3 col-lg-6">
                            <label htmlFor="genderInput" className="form-label">Gender</label>
                            <select name="gender" className='form-control' id="genderInput" value={gender}
                                required
                                onChange={(e) => setgender(e.target.value)}>
                                <option value="">Select Gender</option>
                                <option value="Male">Male</option>
                                <option value="Female">Female</option>
                            </select>
                        </div>
                        <Input
                            label="Time Slot"
                            type="time"
                            bodyclass="col-lg-6"
                            placeholder="Enter your preferred time slot"
                            required
                            value={timeSlot}
                            onChange={(e) => setTimeSlot(e.target.value)}
                        />
                        <Input
                            label="Address"
                            type="text"
                            bodyclass="col-lg-6"
                            placeholder="Enter your address"
                            required
                            value={address}
                            onChange={(e) => setAddress(e.target.value)}
                        />
                        <Input
                            label="City"
                            type="text"
                            bodyclass="col-lg-6"
                            placeholder="Enter your city"
                            required
                            value={city}
                            onChange={(e) => setCity(e.target.value)}
                        />
                        <Input
                            label="State"
                            type="text"
                            bodyclass="col-lg-6"
                            placeholder="Enter your state"
                            required
                            value={state}
                            onChange={(e) => setState(e.target.value)}
                        />
                        <div className={`modal-footer`}>
                            <button
                                type="button"
                                className="btn btn-secondary"
                                data-bs-dismiss="modal"
                                ref={closeButtonRef}
                            >
                                Close
                            </button>


                            <Button
                type="submit"
                children={
                  loadingStatus === "loading" ? (
                    <span
                      className="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  ) : (
                    "Update"
                  )
                }
                className={`btn ${
                  loadingStatus === "loading" ? "disabled" : ""
                }`}
                disabled={loadingStatus === "loading"}
              />
                        </div>
                    </div>
                </form>
            </Model>
        </>
    );
}

export default Member;
