import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { toast } from 'react-toastify';
import { creatememberapi, memberapi, memberupdateapi, memberupdateplanapi, attendanceapi } from "../../../Api_url";
export const token = localStorage.getItem("token")


const headers = {
    Authorization: `Bearer ${token}`,
};
// create member
export const createmember = createAsyncThunk('createmember/member', async (data, { rejectWithValue }) => {
    try {
        const response = await axios.post(creatememberapi, data, { headers })
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response.data.message);
    }
})

// get member
export const getmember = createAsyncThunk('getmember/member', async ({ limit, pagination, search, Gender }) => {
    try {
        const response = await axios.get(`${memberapi}?limit=${limit}&page=${pagination}&searchQuary=${search}&Gender=${Gender}`, { headers });
        return response.data; // Assuming your API response contains a 'members' property
    } catch (error) {
        throw new Error(error.message);
    }
});
// get single member
export const getSinglemember = createAsyncThunk('getSinglemember/member', async (id) => {
    try {
        const response = await axios.get(`${memberapi}/${id}`, { headers });
        return response.data; // Assuming your API response contains a 'members' property
    } catch (error) {
        throw new Error(error.message);
    }
});
// get single attendace
export const getattendance = createAsyncThunk('getattendance/member', async (id) => {
    try {
        const now = new Date();
        // Retrieve the current year
        const year = now.getFullYear();
        const month = now.getMonth() + 1;
        const response = await axios.get(`${attendanceapi}/${id}?year=${year}&month=${month}`, { headers });
        return response.data; // Assuming your API response contains a 'members' property
    } catch (error) {
        throw new Error(error.message);
    }
});
// update
export const updatemembers = createAsyncThunk('updatemembers/member', async ({ selectedId, data }, { rejectWithValue }) => {
    try {
        const response = await axios.put(`${memberupdateapi}/${selectedId}`, data, { headers });
        return response.data.message
    } catch (error) {
        return rejectWithValue(error.response.data.message);
    }
});
// update plan
export const memberupdateplan = createAsyncThunk('memberupdateplan/member', async ({ id, data }, { rejectWithValue }) => {
    try {
        const response = await axios.put(`${memberupdateplanapi}/${id}`, data, { headers });
        return response.data.message
    } catch (error) {
        return rejectWithValue(error.response.data.message);
    }
});

// delete member
export const deletemembers = createAsyncThunk('deletemembers/member', async (memberid, { rejectWithValue }) => {
    try {
        const response = await axios.delete(`${memberapi}/${memberid}`, { headers });
        return response.data._id; // Assuming your API response contains a 'memberid' property
    } catch (error) {
        return rejectWithValue(error.response.data.message);
    }
});



const memberSlice = createSlice({
    name: 'member',
    initialState: {
        members: [],
        Singlemember: {},
        Getattendance: [],
        status: 'idle',
        loadingStatus: 'idle',
        error: null,
        message: null
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            //create member
            .addCase(createmember.pending, (state) => {
                state.loadingStatus = 'loading';
            })
            .addCase(createmember.fulfilled, (state, action) => {
                state.loadingStatus = 'succeeded';
                state.members.push(action.payload.user);
                state.message = action.payload;
                if (state.status === 'succeeded') {
                    toast.success(state.message)
                }
            })
            .addCase(createmember.rejected, (state, action) => {
                state.loadingStatus = 'failed';
                state.error = action.payload;
                if (state.status === 'failed') {
                    toast.error(state.error)
                }
            })
            //update member
            .addCase(updatemembers.pending, (state) => {
                state.loadingStatus = 'loading';
            })
            .addCase(updatemembers.fulfilled, (state, action) => {
                state.loadingStatus = 'succeeded';
                state.message = action.payload;
                if (state.loadingStatus === 'succeeded') {
                    toast.success(state.message)
                }
                state.status = 'idle';

            })
            .addCase(updatemembers.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload;
                if (state.status === 'failed') {
                    toast.error(state.error)
                }
            })
            //update plan
            .addCase(memberupdateplan.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(memberupdateplan.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.message = action.payload;
                if (state.status === 'succeeded') {
                    toast.success(state.message)
                }
                state.status = 'idle';
            })
            .addCase(memberupdateplan.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload;
                if (state.status === 'failed') {
                    toast.error(state.error)
                }
            })

            //getmembers
            .addCase(getmember.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(getmember.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.members = action.payload;
            })
            .addCase(getmember.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload;
            })
            //getSinglemember
            .addCase(getSinglemember.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(getSinglemember.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.Singlemember = action.payload;
            })
            .addCase(getSinglemember.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload;
            })
            //getattendance
            .addCase(getattendance.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(getattendance.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.Getattendance = action.payload;
            })
            .addCase(getattendance.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload;
            })

            //delete members
            .addCase(deletemembers.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(deletemembers.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.members = state.members.filter(members => members.memberid !== action.payload);
                if (state.status === 'succeeded') {
                    toast.success("Member Delete Successfully!!")
                }
                state.status = 'idle'
            })
            .addCase(deletemembers.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload;
            });
    }
});

export default memberSlice.reducer;
