import React, { useRef, useState, useEffect } from "react";
import Button from "../../components/Button/Button";
import { createmember } from "../../ReduxToolkit/Slice/Member/Member";
import { getplan } from "../../ReduxToolkit/Slice/Plan/Plan";
import { useDispatch, useSelector } from "react-redux";
import Model from "../../components/Model/Model";
import Input from "../../components/Input/Input";

function AddMember() {
  const dispatch = useDispatch();
  const closeButtonRef = useRef(null);
  const { status, plans } = useSelector((state) => state.plan);
  const { loadingStatus } = useSelector((state) => state.member);

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [gender, setgender] = useState("");
  const [timeSlot, setTimeSlot] = useState("");
  const [plan, setPlan] = useState("");
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [image, setImage] = useState(null);
  const [password, setPassword] = useState("");
  // show password
  // hide and show password
  const [showPassword, setShowPassword] = useState(false);
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  //  submit data
  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = new FormData();
    data.append("name", name);
    data.append("email", email);
    data.append("phone", phone);
    data.append("gender", gender);
    data.append("timeSlot", timeSlot);
    data.append("address", address);
    data.append("city", city);
    data.append("state", state);
    data.append("image", image);
    data.append("password", password);
    data.append("plan", plan);
    dispatch(createmember(data));
  };
  useEffect(() => {
    if (loadingStatus === "succeeded") {
      setName("");
      setEmail("");
      setPhone("");
      setTimeSlot("");
      setAddress("");
      setCity("");
      setState("");
      setImage(null);
      setPassword("");
      setgender("");
      closeButtonRef.current.click();
    }
  }, [loadingStatus]);
  //
  useEffect(() => {
    if (status === "idle") {
      dispatch(getplan());
    }
  }, [status, dispatch]);
  //

  return (
    <>
      {/* button */}
      <Button
        children={"Register"}
        icon="plus-circle-fill"
        data-bs-toggle="modal"
        data-bs-target="#addmember"
      />
      {/* model */}
      <Model id="addmember" title="Add Member">
        <form onSubmit={handleSubmit}>
          <div className="row text-start">
            <Input
              label="Name"
              type="text"
              bodyclass="col-lg-6"
              placeholder="Enter Your Name"
              required
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
            <Input
              label="Email"
              type="email"
              bodyclass="col-lg-6"
              placeholder="Enter your email"
              required
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <Input
              label="Phone"
              type="tel"
              bodyclass="col-lg-6"
              placeholder="Enter your phone number"
              required
              maxLength={10}
              minLength={10}
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
            />
            <div className="mb-3 col-lg-6">
              <label htmlFor="genderInput" className="form-label">
                Gender
              </label>
              <select
                name="gender"
                className="form-control"
                id="genderInput"
                value={gender}
                required
                onChange={(e) => setgender(e.target.value)}
              >
                <option value="">Select Gender</option>
                <option value="Male">Male</option>
                <option value="Female">Female</option>
              </select>
            </div>
            <div className={`mb-3 col-lg-6`}>
              <label htmlFor="planInput" className="form-label">
                Select Plan
              </label>
              <select
                name="plan"
                className="form-control"
                id="planInput"
                value={plan}
                required
                onChange={(e) => setPlan(e.target.value)}
              >
                <option value="">Select Plan</option>
                {plans.map((data) => (
                  <option value={data._id} key={data._id}>
                    {data.name}/ ₹{data.price}
                  </option>
                ))}
              </select>
            </div>
            <Input
              label="Time Slot"
              type="time"
              bodyclass="col-lg-6"
              placeholder="Enter your preferred time slot"
              required
              value={timeSlot}
              onChange={(e) => setTimeSlot(e.target.value)}
            />
            <Input
              label="Address"
              type="text"
              bodyclass="col-lg-6"
              placeholder="Enter your address"
              required
              value={address}
              onChange={(e) => setAddress(e.target.value)}
            />
            <Input
              label="City"
              type="text"
              bodyclass="col-lg-6"
              placeholder="Enter your city"
              required
              value={city}
              onChange={(e) => setCity(e.target.value)}
            />
            <Input
              label="State"
              type="text"
              bodyclass="col-lg-6"
              placeholder="Enter your state"
              required
              value={state}
              onChange={(e) => setState(e.target.value)}
            />
            <Input
              label="Image"
              type="file"
              bodyclass="col-lg-6"
              onChange={(e) => setImage(e.target.files[0])}
            />

            <div className="d-grid mb-3">
              <label htmlFor="">Set Password</label>
              <div className="input-group">
                <input
                  type={showPassword ? "text" : "password"}
                  required
                  className="form-control"
                  placeholder="********"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
                <sapn
                  className="border px-2 bg-light text-center pt-1"
                  onClick={togglePasswordVisibility}
                >
                  {showPassword ? "Hide" : "Show"}
                </sapn>
              </div>
            </div>

            <div className={`modal-footer`}>
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
                ref={closeButtonRef}
              >
                Close
              </button>

              <Button
                type="submit"
                children={
                  loadingStatus === "loading" ? (
                    <span
                      className="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  ) : (
                    "Submit"
                  )
                }
                className={`btn ${
                  loadingStatus === "loading" ? "disabled" : ""
                }`}
                disabled={loadingStatus === "loading"}
              />
            </div>
          </div>
        </form>
      </Model>
    </>
  );
}

export default AddMember;
